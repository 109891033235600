import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm } from '@angular/forms';
import { SessionDetails } from '../../../../utility/session';
import { NgxSpinnerService } from "ngx-spinner";
import { PcaDetailsService } from '../../Services/pcadetailsservice.service';
import { KeysLogPipe } from '../../../store/pipes/keyslog';
import { CSTDateConvertPipe } from '../../../store/pipes/cstDateConvert';
import { TimeToDatePipe } from '../../../contractaggreement/pipes/time-to-date.pipe';
import { DatePipe } from '@angular/common';
import { Util } from '../../../../utility/util';
import { ErrorMessages } from '../../../../config-files/error-messages';
import { AlertService } from '../../../../alert.service';
import { environment } from '../../../../../environments/environment';
import { ClinicsUpdateHistoryLogList } from '../../../../models/local-clinic-details';
import { PCADetailsFields } from '../../../../JSON/pcaDetailsFields';
import { ConfirmationService } from 'primeng/api';
import { PCATestingEventDetails } from '../../../../models/pcaTestingEventDetails';
import { ClinicDetailsDelete } from '../../../../models/clinicDetailsDelete';
import { FormCanDeactivate } from '../../../../guards/form-can-deactivate/form-can-deactivate';
import { MessageServiceService } from '../../../store/services/message-service.service';
import { PcacontractAgreementService } from '../../Services/pcacontract-agreement.service';
import { LulaAso } from '../../../../models/pcaTandC';
import { LulaAsoRelease2 } from '../../../../models/pcaTandC-release2';

@Component({
  selector: 'app-pcadetails',
  templateUrl: './pcadetails.component.html',
  styleUrls: ['./pcadetails.component.css'],
  providers: [TimeToDatePipe, DatePipe, KeysLogPipe, CSTDateConvertPipe]
})
export class PcadetailsComponent extends FormCanDeactivate implements OnInit {

  @ViewChild('form')
  form: NgForm;

  showCanRouteChange: boolean;

  showHints: boolean = ((localStorage.getItem("hintsOff") || sessionStorage.getItem("hintsOff")) === 'yes') ? false : true;
  pageName: string = "pcadetails";

  pcaDetailsForm: FormGroup;

  private group = this.formBuilder.group({});
  defaultDate: Date = new Date();
  maxDateValue: Date = new Date(this.defaultDate.getFullYear() + 5, this.defaultDate.getMonth(), this.defaultDate.getDate());
  minDateValue: Date = new Date();
  defaultStartTime: Date;
  invalidDates: Array<Date> = [];
  eventDetails: any;
  userProfile: any;
  updateLogHistory: any = [];

  validateCancel: boolean = null;
  validateComplete: boolean = null;
  validateConfirm: boolean = null;

  private pcaDetailsSource: PCATestingEventDetails = new PCATestingEventDetails();
  pcaDetails: PCATestingEventDetails = new PCATestingEventDetails();

  usPhoneNoRegEx: RegExp =  /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/

  deleteAgreement: boolean = false;

  isAdmin: boolean = false;

  stepValue: any = 1;
  viewSteps: boolean = true;
  totalSteps: number = 3;
  currentStep: number;

  showSimpleDialog: boolean = false;
  isErrorMessage: boolean = false;
  dialogBody: string = "";
  dialogHeader: string = "";

  changedDateTime: boolean = false;
  changeDateTimeHeader: string = "";
  changeDateTimeBody: string = "";
  changeDateTimeActionStatus: string = "";

  servicesQuantityText = "";

  clinicNotConfirmedMsgDlg = false;
  noPCAData: boolean = false;
  cancelEvent: boolean = false;
  
  errorPopUp: boolean = false;
  showRemoveCancelledStatus: boolean = false;
  showRemoveCancelledStatusDlg:boolean = false;
  showRemoveCancelledStatusResult:boolean = false;

  dialogSummary:string;
  dialogMsg:string;
  clinicAgreementDateApproved: any;
  oldPcaReleaseDate: any;
  PcaReleaseDate2022_1: any;
  serviceLabel: string = "";

  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private pcaDetailsService: PcaDetailsService, private _keysPipe: KeysLogPipe, private _cstDate: CSTDateConvertPipe, private utility: Util, private _alertService: AlertService, private _datePipe: DatePipe,
    private confirmationService: ConfirmationService, private messageService: MessageServiceService,
    private _contractService:PcacontractAgreementService) {
    super();
    this.messageService.getCanShowRouteChangeConfirmation().subscribe(message => {
      this.showCanRouteChange = message.text;
    });
    this.userProfile = SessionDetails.GetUserProfile();

    this.setMinMaxDateTimes();

    this.eventDetails = SessionDetails.GetEventDetails();

    this.servicesQuantityText = (this.eventDetails.clinicTypeCode == 11) ? 'Registered Organ Donors' : 'Preliminary Positives';

    this.spinner.show();
  }

  ngOnInit() {

    this.isAdmin = this.userProfile.isAdmin;
    this.pcaDetailsForm = this.group;
        this.getPCADetails();

    this.createForm();
    this.oldPcaReleaseDate = new LulaAso().tnCCutOffDate;
    this.PcaReleaseDate2022_1 = new LulaAsoRelease2().tnCCutOffDate;
  }

  getContractAgreementData(clinicAgreementPk){
    this._contractService.getPCAContractAgreement(clinicAgreementPk, null).subscribe((data:any) => {                  
      this.spinner.hide();      
      this.clinicAgreementDateApproved = Util.getFormatedDate(data.dateApproved);
    });
  }
  //Creates Form Group with created form controls.
  createForm() {
    let user_role: string = "";
    let need_req_qty_validation = ((user_role) ? Validators.required : null);

    //Store Information
    this.group.addControl("assignedTo", this.formBuilder.control("", Validators.required));

    //Testing Organization Information.
    this.group.addControl("clientName", this.formBuilder.control("", Validators.compose([Validators.required])));
    this.group.addControl("contactEmail", this.formBuilder.control("", Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)])));
    this.group.addControl("firstName", this.formBuilder.control("", Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z\\s]*$")])));
    this.group.addControl("lastName", this.formBuilder.control("", Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z\\s-]*$")])));
    this.group.addControl("phone", this.formBuilder.control("", Validators.compose([Validators.required])));
    this.group.addControl("additionalComments", this.formBuilder.control("", null));
    this.group.addControl("licenseAgreement", this.formBuilder.control(""));

    //Testing & Screening.
    this.group.addControl("clinicImmunizationList", this.formBuilder.array([]));

    //Testing Locations.
    this.group.addControl("clinicList", this.formBuilder.array([]));

    //Feedback notes
    this.group.addControl("feedbackNotes", this.formBuilder.control("", null));
  }

  //Initializes Testing And Screening Info.
  initTestingAndScreeningInfo() {
    let imz_qty_controls: FormArray = new FormArray([]);
    this.pcaDetails.clinicList[0].clinicImzQtyList.forEach(imz => imz_qty_controls.push(
      this.formBuilder.group({
        pk: this.formBuilder.control(imz.clinicPk),
        immunizationPk: this.formBuilder.control(imz.immunizationPk),
        estimatedQuantity: this.formBuilder.control(imz.estimatedQuantity, Validators.compose([Validators.required])),
        immunizationName: this.formBuilder.control(imz.immunizationName),
        paymentTypeName: this.formBuilder.control(imz.paymentTypeName),
        paymentTypeId: this.formBuilder.control(imz.paymentTypeId),
        price: this.formBuilder.control(this.getPrice(imz.immunizationPk, imz.paymentTypeId)),
        totalImmAdministered: this.formBuilder.control(imz.totalImmAdministered),
        preliminaryPositives: this.formBuilder.control(imz.preliminaryPositives)
      })
    ));
    return imz_qty_controls;
  }

  //Initializes the Testing Locations.
  initTestingLocations() {
    let location_controls: FormArray = new FormArray([]);
    this.pcaDetails.clinicList.forEach(loc => {
      location_controls.push(
        this.formBuilder.group(
          {
            clinicPk: this.formBuilder.control(loc.clinicPk),
            clinicStoreId: this.formBuilder.control(loc.clinicStoreId),
            clinicNumber: this.formBuilder.control(loc.clinicNumber),
            naClinicLocation: this.formBuilder.control(loc.naClinicLocation.toUpperCase().replace("LOCATION ", "").trim()),
            naClinicAddress1: this.formBuilder.control(loc.naClinicAddress1, Validators.required),
            naClinicAddress2: this.formBuilder.control(loc.naClinicAddress2, null),
            naClinicCity: this.formBuilder.control(loc.naClinicCity, Validators.required),
            naClinicState: this.formBuilder.control(loc.naClinicState, Validators.required),
            naClinicZip: this.formBuilder.control(loc.naClinicZip, Validators.required),
            clinicDate: this.formBuilder.control(loc.clinicDate, [Validators.required]),
            naClinicStartTime: this.formBuilder.control(loc.naClinicStartTime, Validators.required),//Need to change
            naClinicEndTime: this.formBuilder.control(loc.naClinicEndTime, Validators.required),//Need to change
            naContactFirstName: this.formBuilder.control(loc.naContactFirstName.trim(), Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z\\s]*$")])),
            naContactLastName: this.formBuilder.control(loc.naContactLastName, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z\\s-]*$")])),
            confirmedClientName: this.formBuilder.control(loc.confirmedClientName, Validators.compose([null, Validators.pattern(/^[a-zA-Z]*$/)])),
            naClinicContactPhone: this.formBuilder.control(loc.naClinicContactPhone, Validators.compose([Validators.required])),
            naContactEmail: this.formBuilder.control(loc.naContactEmail, Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]$/)])),
            isCancelled: this.formBuilder.control(loc.isCancelled == true ? 1 : 0, null),
            isConfirmed: this.formBuilder.control(loc.isConfirmed == true ? 1 : 0, null),
            isCompleted: this.formBuilder.control(loc.isCompleted == true ? 1 : 0, null),
            coOutreachTypeId: this.formBuilder.control(loc.coOutreachTypeId, null),
            coOutreachTypeDesc: this.formBuilder.control(loc.coOutreachTypeDesc, null),
            clinicLatitude: this.formBuilder.control(loc.clinicLatitude, null),
            clinicLongitude: this.formBuilder.control(loc.clinicLongitude, null),
            clinicImzQtyList: this.addInitialclinicImmunizationsInfo(),
          }
        )
      );
    });
    return location_controls;
  }

  //Adds Initial Clinic Immunizations (Testing & Screening services) info
  addInitialclinicImmunizationsInfo() {
    let imz_array: any[] = [];
    this.pcaDetails.clinicList[0].clinicImzQtyList.forEach(imz => {
      imz_array.push(
        this.formBuilder.group(
          {
            clinicPk: this.formBuilder.control(imz.clinicPk),
            immunizationPk: this.formBuilder.control(imz.immunizationPk),
            estimatedQuantity: this.formBuilder.control(imz.estimatedQuantity),
            immunizationName: this.formBuilder.control(imz.immunizationName),
            paymentTypeName: this.formBuilder.control(imz.paymentTypeName),
            paymentTypeId: this.formBuilder.control(imz.paymentTypeId),
            totalImmAdministered: this.formBuilder.control(imz.totalImmAdministered),
            preliminaryPositives: this.formBuilder.control(imz.preliminaryPositives)
          }
        )
      )
    });

    return this.formBuilder.array(imz_array);

  }

  //Returns the price of the speficied immunization from clinicImmunizationList dictionary.
  getPrice(immunization_pk, paymentTypeId): Number {
    let imz_array: any[] = [];
    let price;
    price = this.pcaDetails.clinicImmunizationList.filter(imz => (imz.immunizationPk == immunization_pk && imz.paymentTypeId == paymentTypeId));
    return price[0].price;
  }

  //setting Min & Max, default and invalid date values
  setMinMaxDateTimes() {
    let today = new Date();
    if (this.userProfile.userRole.toLowerCase() == "store manager" || this.userProfile.userRole.toLowerCase() == "pharmacy manager") {
      this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);

    } else {
      this.defaultDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      this.minDateValue = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    this.maxDateValue = new Date(today.getFullYear() + 5, today.getMonth(), today.getDate() - 1);
    this.defaultStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0);
  }

  //Call the PCA Details information by initiating the API service call.
  //Sets data formats in the reponse data.
  //Generates the update Log History.
  //Sets the steps values.
  //Sets the control values in the form using the response data.
  getPCADetails() {
    this.spinner.show();
    this.pcaDetailsService.getPcaDetails(this.eventDetails.clinicPk, this.userProfile.userRole).subscribe((data:any) => {      
      this.getContractAgreementData(data.opportunityInfo.clinicAgreementPk);
      if(data.errorS.errorCode === -7){
        this.noPCAData = true;
        this.showDialog("Alert", ErrorMessages.eventRemoved, false,false);
        return false;
      }

      this.formatGetDataValues(data);

      this.pcaDetailsSource = JSON.parse(JSON.stringify(data));
      this.pcaDetails = data;
      if(this.pcaDetails.clinicImmunizationList.length){
        let serviceLabelList = this.pcaDetails.clinicImmunizationList.map((immunization) => {
          let idx = immunization.immunizationName.indexOf("testing");
          return immunization.immunizationName.substring(0,idx);
        });
        serviceLabelList = serviceLabelList.sort();
        if(serviceLabelList.length > 1){
          let lastEle = serviceLabelList.pop();
          let label =  `${serviceLabelList.join(',')} and ${lastEle}`;
          this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
        } else {
          let label =  `${serviceLabelList.join(',')}`;
          this.serviceLabel = `${label.replace(" ,", ", ")} testing and screening services and ${label.replace(" ,",", ").replace("HIV", "HIV/AIDS")} counseling to individuals`;
        }
      }

      if ((this.pcaDetailsSource.clinicList[0].isCancelled == true || this.pcaDetailsSource.clinicList[0].isConfirmed == true || this.pcaDetailsSource.clinicList[0].isCompleted == true) && this.isAdmin) {
        this.showRemoveCancelledStatus= true;
      }

      // if(this.pcaDetailsSource.clinicList[0].isCancelled == true){
      //   this.showRemoveCancelledStatus = true;
      // }
      this.makeUpdateLogHistory();
      this.setStepValus();
      this.setFormControlValues();
    });
  }

  //Extracts and makes the updateLogHistory from the received data
  makeUpdateLogHistory() {
    var ala_sql = require('alasql');
    let source = this.pcaDetails.clinicsUpdateHistoryLogList;
    source.reverse();
    let updateLogHistoryQuery = ala_sql("SELECT updatedOn, updateAction, ARRAY({updateSubCategory:x.updateSubCategory, updatedField:x.updatedField}) AS updateField, ARRAY({updateSubCategory:x.updateSubCategory, updatedValue:x.updatedValue}) as updatedValue, x.updatedBy updatedBy \
 FROM ? x \
 GROUP BY  updatedOn,updateAction,updatedBy ORDER BY updatedOn DESC", [source]);
    for (var i = 0; i < updateLogHistoryQuery.length; i++) {
      updateLogHistoryQuery[i].updateField = this._keysPipe.transform(updateLogHistoryQuery[i].updateField);
      updateLogHistoryQuery[i].updatedValue = this._keysPipe.transform(updateLogHistoryQuery[i].updatedValue);
    }
    this.updateLogHistory = updateLogHistoryQuery;
  }

  //formats the received data.
  formatGetDataValues(data) {
    for (let i = 0; i < data.clinicList.length; i++) {
      if (data.clinicList[i].clinicDate != null && data.clinicList[i].clinicDate != undefined && data.clinicList[i].clinicDate != "") {
        /*var c_date = new Date(data.clinicList[i].clinicDate);
        data.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();*/
        var temp_dt = data.clinicList[i].clinicDate.substring(0, data.clinicList[i].clinicDate.indexOf('T'));
        let temp_dt_parts = temp_dt.split('-');
        var dt = new Date(temp_dt_parts[0], temp_dt_parts[1] - 1, temp_dt_parts[2], 18, 30, 30);
        data.clinicList[i].clinicDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 18, 30, 30);
      } else {
        var c_date = new Date();
        data.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
      }
      data.clinicList[i].clinicImzQtyList.forEach(imzQtyItem => {
        imzQtyItem["imzNameAndPmtMethod"] = "";//imzQtyItem.immunizationName;
      });

      if (data.clinicList[i].naClinicStartTime != null) {
        if (data.clinicList[i].naClinicStartTime.indexOf(' ') == -1) {
          data.clinicList[i].naClinicStartTime = data.clinicList[i].naClinicStartTime.toLocaleLowerCase().replace('am', ' AM').replace('pm', ' PM');
        }
      }
      if (data.clinicList[i].naClinicEndTime != null) {
        if (data.clinicList[i].naClinicEndTime.indexOf(' ') == -1) {
          data.clinicList[i].naClinicEndTime = data.clinicList[i].naClinicEndTime.toLocaleLowerCase().replace('am', ' AM').replace('pm', ' PM');
        }
      }
      data.clinicList[i].naContactFirstName = data.clinicList[i].naContactFirstName.trim();
      data.clinicList[i].naContactLastName = data.clinicList[i].naContactLastName.trim();
      data.clinicList[i].naClinicAddress1 = data.clinicList[i].naClinicAddress1.trim();
      data.clinicList[i].naClinicAddress2 = data.clinicList[i].naClinicAddress2.trim();
      data.clinicList[i].naClinicCity = data.clinicList[i].naClinicCity.trim();
      data.clinicList[i].naClinicState = data.clinicList[i].naClinicState.trim();
      data.clinicList[i].naClinicZip = data.clinicList[i].naClinicZip.trim();
      data.clinicList[i].naClinicContactPhone = data.clinicList[i].naClinicContactPhone.trim();
    }
    data.opportunityInfo.firstName = data.opportunityInfo.firstName.trim();
    data.opportunityInfo.lastName = data.opportunityInfo.lastName.trim();
    data.opportunityInfo.phone = data.opportunityInfo.phone.trim();
    data.opportunityInfo.businessContactEmail = data.opportunityInfo.businessContactEmail.trim();
  }

  //Sets the step values.
  setStepValus() {
    if (this.pcaDetails.clinicList[0].isConfirmed == true) {
      this.validateConfirm = true;
      this.currentStep = 2
      this.stepValue = 2;
    }
    if (this.pcaDetails.clinicList[0].isCancelled == true) {
      this.validateCancel = true;
      this.currentStep = -1
      this.stepValue = -1;
    }
    if (this.pcaDetails.clinicList[0].isCompleted == true) {
      this.validateCancel = true;
      this.validateComplete = true;
      this.validateConfirm = true;
      this.currentStep = 3
      this.stepValue = 3;
    }
  }

  //sets the values to the controls in the form using received data.
  setFormControlValues() {
    this.pcaDetailsForm.controls['assignedTo'].setValue(this.pcaDetails.opportunityInfo.storeId)
    this.pcaDetailsForm.controls["clientName"].setValue(this.pcaDetails.opportunityInfo.businessName);
    this.pcaDetailsForm.controls["contactEmail"].setValue(this.pcaDetails.opportunityInfo.businessContactEmail);
    this.pcaDetailsForm.controls["firstName"].setValue(this.pcaDetails.opportunityInfo.firstName.trim());
    this.pcaDetailsForm.controls["lastName"].setValue(this.pcaDetails.opportunityInfo.lastName);
    this.pcaDetailsForm.controls["phone"].setValue(this.pcaDetails.opportunityInfo.phone);

    this.pcaDetailsForm.controls["feedbackNotes"].setValue(this.pcaDetails.feedback);
    this.pcaDetailsForm.controls["feedbackNotes"].setValidators(null);
    this.pcaDetailsForm.controls["additionalComments"].setValue(this.pcaDetails.opportunityInfo.naClinicAddlComments);
    this.pcaDetailsForm.controls["licenseAgreement"].setValue(this.pcaDetails.opportunityInfo.licenseAgreementNum);

    this.pcaDetailsForm.setControl('clinicImmunizationList', this.initTestingAndScreeningInfo());

    this.pcaDetailsForm.setControl('clinicList', this.initTestingLocations());

    this.pcaDetailsForm.updateValueAndValidity();
  }

  //Initiates the save functionality for the changes in screen.
  saveChanges() {
    this.validateAndSaveOrContinue("Submit");
  }

  //Initiates the confirm clinic functionality.
  confirmClinic() {
    this.validateAndSaveOrContinue("Confirmed");
  }

  //Initiates the complete clinic functionality.
  completeClinic() {
    if (this.pcaDetailsSource.clinicList[0].isConfirmed == false) {
      this.clinicNotConfirmedMsgDlg = true;
      this.showClinicNotConfirmedDialog('Alert', ErrorMessages['clinicNotConfirmedMsg']);
      return false;
    } else {
      this.validateAndSaveOrContinue("Completed");
    }
  }

  //Initiates the Cancel clinic functionality.
  cancelClinic() {
    this.cancelEvent = false;
    this.validateAndSaveOrContinue("Cancelled");
  }

  //Checks the control is a valid field.
  isFieldValid(field: string) {
    if (this.pcaDetailsForm.get(field) != null)
      return !this.pcaDetailsForm.get(field).valid && this.pcaDetailsForm.get(field).touched;
  }

  //Returns the error css for invalid field.
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  //Returns the error css for the fields in the array.
  displayFieldCssForArray(field: string, index: number) {
    let return_value = this.isValidArrayField(field, index);
    return {
      'has-error': return_value,
      'has-feedback': return_value
    };
  }

  //Returns the error css for the quantity fields.
  displayFieldCssForLocImm(field: string, i: number, im: number) {
    const control = <FormArray>this.pcaDetailsForm.controls['clinicList'];
    const immArray = <FormArray>control.controls[i].get('clinicImzQtyList');
    const estQty = immArray.controls[im].get('estimatedQuantity');
    let return_val = !estQty.valid && estQty.touched;
    return {
      'has-error': return_val,
      'has-feedback': return_val
    };
  }

  //Verifies the field in an array is valid/invalid.
  isValidArrayField(fields: string, index: number) {
    let return_value = false;
    Object.keys(this.pcaDetailsForm.controls).forEach(field => {
      const control = this.pcaDetailsForm.get(field);
      if (control instanceof FormArray) {
        if (control.controls[index] !== undefined) {
          if (control.controls[index].get(fields) != null) {
            return_value = !control.controls[index].get(fields).valid && control.controls[index].get(fields).touched;
          }
        }
      }
      if (return_value) {
        return false;
      }
    });
    return return_value;
  }

  //Generates the required JSON object and call the function initiate the save API.
  continueWithSaving(action_status) {
    var error_message = "";
    let pca_details = this.saveData(action_status);
    if (action_status.toLowerCase() == 'completed') {
      error_message = ErrorMessages['eventComplete'];
    } else if (action_status.toLowerCase() == 'confirmed') {
      error_message = ErrorMessages['eventConfirm'];
    } else if (action_status.toLowerCase() == 'cancelled') {
      error_message = ErrorMessages['eventCancel'];
    }
    else {
      error_message = ErrorMessages['eventSave'];
    }

    /*this._alertService.clear();
    if (action_status == null || action_status.toLowerCase() != 'completed') {
      this._alertService.info(ErrorMessages['eventDateReminderAfter2WeeksEN'], false);
    }*/
    this.scrolling();
    this.updatePCADetails(pca_details, error_message);
  }

  //Generates the object to save the data.
  saveData(action_status): any {

    let control = <FormArray>this.pcaDetailsForm.controls['clinicList'];
    let index;
    for (index = 0; index < control.controls.length; index++) {

      let address1: string = control.controls[index].get('naClinicAddress1').value;
      let address2: string = control.controls[index].get('naClinicAddress2').value;

      let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
      address = address.replace(/\s/g, '');
      if (address.toLowerCase().includes("pobox")) {
        this.dialogHeader = "Alert";
        this.dialogBody = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

        this.showDialog(this.dialogHeader, this.dialogBody, false,false);
        return;
      }

    }

    this.updateDataFromFormFields(this.pcaDetails);

    this.pcaDetails.clinicsUpdateHistoryLogList = [];

    this.pcaDetails.clinicList.forEach(item => {
      try {
        item.naClinicStartTime = this._datePipe.transform(item.naClinicStartTime, "shortTime");
      } catch (e) {
        console.log('save changes' + e);
      }
      try {
        item.naClinicEndTime = this._datePipe.transform(item.naClinicEndTime, "shortTime");
      } catch (e) {
        console.log('save changes' + e);
      }
    });

    this.pcaDetails.userPk = this.pcaDetailsSource.userPk;

    this.pcaDetails.clinicContactStatus = action_status == null ? "Submit" : this.pcaDetailsSource.clinicContactStatus;

    this.makeLogHisoryList();

    this.pcaDetails.userPk = this.userProfile.userPk;

    if (action_status != null) {
      if (action_status.toLowerCase() == "cancelled") {
        this.pcaDetails.clinicContactStatus = "Cancelled";
        this.pcaDetails.clinicList[0].isCancelled = 1;
        this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Cancelled", "", "", ""));
      } else if (action_status.toLowerCase() == "completed") {
        this.pcaDetails.clinicContactStatus = "Completed";
        this.pcaDetails.clinicList[0].isCancelled = 1;
        this.pcaDetails.clinicList[0].isCompleted = 1;
        this.pcaDetails.clinicList[0].isConfirmed = 1;
        this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Completed", "", "", ""));
      } else if (action_status.toLowerCase() == "confirmed") {
        this.pcaDetails.clinicContactStatus = "Confirmed";
        this.pcaDetails.clinicList[0].isConfirmed = 1;
        var confirmed_status = 'Confirmed';
        this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem(confirmed_status, "", "", ""));
      } else {
        this.pcaDetails.clinicContactStatus = "Submit";
      }
    }

    this.pcaDetails.clinicList.forEach(item => {
      try {
        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });
      } catch (e) {
        console.log('save changes' + e);
      }
    });
    return this.pcaDetails;
  }

  //Updates the save data object with the values from the FormFields.
  updateDataFromFormFields(target_obj) {
    target_obj.opportunityInfo.businessContactEmail = this.pcaDetailsForm.value.contactEmail;
    target_obj.opportunityInfo.firstName = this.pcaDetailsForm.value.firstName;
    target_obj.opportunityInfo.lastName = this.pcaDetailsForm.value.lastName;
    target_obj.opportunityInfo.naClinicAddlComments = this.pcaDetailsForm.value.additionalComments;

    target_obj.opportunityInfo.phone = this.pcaDetailsForm.value.phone;
    target_obj.errorS.errorCode = 0;
    target_obj.errorS.errorMessage = null;

    target_obj.proofOfDocs = [];

    for (let i = 0; i < this.pcaDetailsForm.value.clinicList.length; i++) {
      for (let j = 0; j < this.pcaDetailsForm.value.clinicImmunizationList.length; j++) {
        target_obj.clinicList[i].clinicImzQtyList[j].totalImmAdministered =
          this.pcaDetailsForm.value.clinicImmunizationList[j].totalImmAdministered;
        target_obj.clinicList[i].clinicImzQtyList[j].preliminaryPositives =
          this.pcaDetailsForm.value.clinicImmunizationList[j].preliminaryPositives;
      }

      if (this.pcaDetailsForm.value.clinicList[i].clinicDate != null && this.pcaDetailsForm.value.clinicList[i].clinicDate != '') {
        let c_date = new Date(this.pcaDetailsForm.value.clinicList[i].clinicDate);
        target_obj.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
      }
      else {
        target_obj.clinicList[i].clinicDate = null;
      }
      if (this.pcaDetailsSource.clinicList[i].clinicDate !== null && this.pcaDetailsSource.clinicList[i].clinicDate !== '') {
        let c_date = new Date(this.pcaDetailsSource.clinicList[i].clinicDate);
        this.pcaDetailsSource.clinicList[i].clinicDate = c_date.getMonth() + 1 + "/" + c_date.getDate() + "/" + c_date.getFullYear();
      }

      target_obj.clinicList[i].naClinicContactPhone = this.pcaDetailsForm.value.clinicList[i].naClinicContactPhone;

      target_obj.clinicList[i].naContactFirstName = this.pcaDetailsForm.value.clinicList[i].naContactFirstName;
      target_obj.clinicList[i].naContactLastName = this.pcaDetailsForm.value.clinicList[i].naContactLastName;
      target_obj.clinicList[i].naContactEmail = this.pcaDetailsForm.value.clinicList[i].naContactEmail;
      target_obj.clinicList[i].naClinicStartTime = this.pcaDetailsForm.value.clinicList[i].naClinicStartTime;
      target_obj.clinicList[i].naClinicEndTime = this.pcaDetailsForm.value.clinicList[i].naClinicEndTime;
      target_obj.clinicList[i].confirmedClientName = this.pcaDetailsForm.value.clinicList[i].confirmedClientName;
    }

    target_obj.clinicList[0].naClinicContactPhoneExt = this.pcaDetailsSource.clinicList[0].naClinicContactPhoneExt;


    // for (let j = 0; j < target_obj.clinicList.length; j++) {
    for (let i = 0; i < target_obj.clinicList[0].clinicImzQtyList.length; i++) {
      target_obj.clinicList[0].clinicImzQtyList[i].estimatedQuantity =
        this.pcaDetailsForm.value.clinicImmunizationList[i].estimatedQuantity;

      target_obj.clinicList[0].clinicImzQtyList[i]["imzNameAndPmtMethod"] = "";//target_obj.clinicList[0].clinicImzQtyList[i].immunizationName + "<br />" + target_obj.clinicList[0].clinicImzQtyList[i].paymentTypeName;
    }
    this.pcaDetailsSource.clinicList.forEach(list => {
      list.naClinicLocation = list.naClinicLocation.trim();
    });

    target_obj.feedback = (this.pcaDetailsForm.value.feedbackNotes) ? this.pcaDetailsForm.value.feedbackNotes : this.pcaDetailsSource.feedback;
  }

  //Makes Log History List based on the updated fields.
  makeLogHisoryList = function () {
    //Identifying the updated value
    let data: any = this.utility.getDifference(this.pcaDetailsSource, this.pcaDetails);
    console.log(data);
    let pcaAliasName = PCADetailsFields;

    for (let item in data) {
      if (item != "userPk" && item != "clinicContactStatus") {
        let opp: any = Object.getOwnPropertyNames(data[item]);
        if (typeof data[item] == 'object') {
          var temp_obj;
          if (item == "clinicList") {
            temp_obj = data[item][0];
          } else {
            temp_obj = data[item];
          }
          for (let updated_item in temp_obj) {
            let value = temp_obj[updated_item];
            if (typeof value == 'object') {

              for (let updated_imz_item in temp_obj[updated_item]) {
                for (let updated_imz_qty in temp_obj[updated_item][updated_imz_item]) {
                  value = temp_obj[updated_item][updated_imz_item][updated_imz_qty];
                  let alias_and_group_name = pcaAliasName.map(group_item => {
                    if (group_item.aliasNames[updated_imz_qty]) {
                      return group_item.aliasNames[updated_imz_qty] + "~" + group_item.groupName
                    }
                  }).filter(test_item => test_item != undefined);
                  if (alias_and_group_name.length > 0) {
                    let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                    let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1);
                    alias_name = (this.eventDetails.clinicTypeCode == 11) ? ((alias_name.indexOf('_') > -1) ? alias_name.substring(alias_name.indexOf('_') + 1) : alias_name) : alias_name;
                    //alias_name = /*temp_obj[updated_item][updated_imz_item]["imzNameAndPmtMethod"] + '<br />' +*/ (this.eventDetails.clinicTypeCode == 11)? 'Registered Organ Donors' : 'Preliminary Positives'; alias_name;
                    this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
                  }
                }
              }

            }
            else {
              let alias_and_group_name = pcaAliasName.map(group_item => {
                if (group_item.aliasNames[updated_item]) {
                  return group_item.aliasNames[updated_item] + "~" + group_item.groupName
                }
              }).filter(test_item => test_item != undefined);
              if (alias_and_group_name.length > 0) {
                let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
                let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1);
                //alias_name = (this.eventDetails.clinicTypeCode == 11) ? ((alias_name.indexOf('_') > -1) ? alias_name.substring(alias_name.indexOf('_') + 1) : alias_name) : alias_name;
                this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
              }
            }
          }
        }
        else {
          let value = data[item];
          let alias_and_group_name = pcaAliasName.map(group_item => {
            if (group_item.aliasNames[item]) {
              return group_item.aliasNames[item] + "~" + group_item.groupName
            }
          }).filter(test_item => test_item != undefined);
          if (alias_and_group_name.length > 0) {
            let alias_name = alias_and_group_name[0].substring(0, alias_and_group_name[0].indexOf('~'));
            let group_name = alias_and_group_name[0].substring(alias_and_group_name[0].indexOf('~') + 1);
            //alias_name = (this.eventDetails.clinicTypeCode == 11) ? ((alias_name.indexOf('_') > -1) ? alias_name.substring(alias_name.indexOf('_') + 1) : alias_name) : alias_name;
            this.pcaDetails.clinicsUpdateHistoryLogList.push(this.makeLogHistoryItem("Update", value, group_name, alias_name));
          }
        }
      }
    }
  }

  //Creates an Log History Object for an updated field.
  makeLogHistoryItem = function (update_status, updated_value, group_name, item_alias_name) {
    var logHistory = new ClinicsUpdateHistoryLogList();
    logHistory.pk = 0;
    logHistory.updateAction = update_status;
    logHistory.updatedField = item_alias_name
    logHistory.updatedValue = updated_value;
    logHistory.updateSubCategory = group_name;
    logHistory.updatedBy = this.userProfile.email;
    logHistory.updatedOn = new Date();
    return logHistory;
  }

  scrolling() {
    window.scrollTo(0, 0);
  }

  //Initiates the UPDATE API call to save the modified data.
  updatePCADetails(pca_details: any, error_message: string) {
    this.pcaDetailsService.updatePCADetails(pca_details.clinicList[0].clinicPk,
      pca_details).subscribe((res: any) => {
        //if (res.status == "200") {
          if (res.errorS != null) {
            if (res.errorS.errorCode != 0) {
              this.showDialog(ErrorMessages['eventDetails'], res.errorS.errorMessage, true,false);
            }
            else {
              this.showDialog(ErrorMessages['eventDetails'], error_message, false,false);
            }
          }
          else {
            this.showDialog(ErrorMessages['eventDetails'], error_message, false,false);
          }
        //}
      },
        err => {
          // switch (err) {
          //   default:
          //     this.showDialog(ErrorMessages['serverError'], err.json().Message, true);
          //     break;
          //   case 500:
          this.showDialog(ErrorMessages['serverError'], err.json().Message, true,false);
          //     break;
          // }
        });
  }

  //Updates the modified field label in the object.
  updateLabel(obj: any) {
    obj.value["imzNameAndPmtMethod"] = "";// obj.value.immunizationName + "<br />" + obj.value.paymentTypeName;
  }

  //Allows only numbers input into the field.
  onlyNumbers(event: any) {
    Util.onlyNumbers(event);
  }

  //Checks the clinic date is with in 2 weeks the current date.
  isClinicDateReminderBefore2WksReq(action_status: string): boolean {
    let is_clinic_date_reminder: boolean = false;

    try {
      let today = new Date();
      let start_index = 0;
      if (action_status != null && action_status.toLowerCase() == "completed") {
        if (this.pcaDetailsForm.value.clinicList.length > 1) {
          start_index = 1;
        }
      }
      for (let index = start_index; index < this.pcaDetailsForm.value.clinicList.length; index++) {
        const loc = this.pcaDetailsForm.value.clinicList[index];
        let clinicDate: Date = null;
        let initialClinicDate: Date = null;
        if (typeof (loc.clinicDate) == "string") {
          var dt = loc.clinicDate.split("/");

          clinicDate = new Date(dt[2], dt[0] - 1, dt[1]);
        } else {
          clinicDate = loc.clinicDate;
        }
        if (typeof (this.pcaDetailsSource.clinicList[0].clinicDate) == "string") {
          var idt = this.pcaDetailsSource.clinicList[0].clinicDate.split("/");
          initialClinicDate = new Date(parseInt(idt[2]), parseInt(idt[0]) - 1, parseInt(idt[1]));
        } else {
          initialClinicDate = this.pcaDetailsSource.clinicList[0].clinicDate;
        }
        if (initialClinicDate != null && index <= 0 &&
          (initialClinicDate.getFullYear() != clinicDate.getFullYear() || initialClinicDate.getMonth() != clinicDate.getMonth() ||
            initialClinicDate.getDate() != clinicDate.getDate())) {
          // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
          //   : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          //     new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          if (diffDays < 14) {
            is_clinic_date_reminder = true;
          }
        }
        else if (this.pcaDetailsForm.value.clinicList.length > 1 && index > 0) {
          // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
          //   : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          //     new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          if (diffDays < 14) {
            is_clinic_date_reminder = true;
          }
        }
        else if (initialClinicDate == null) {
          // let diffDays: number = clinicDate.getMonth() == today.getMonth() ? Math.round(clinicDate.getDate() - today.getDate())
          //   : this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
          //     new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          let diffDays: number = this.utility.getDiffDays(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0));
          if (diffDays < 14) {
            is_clinic_date_reminder = true;
          }
        }
      }
    } catch (e) {

      return e;
    }
    return is_clinic_date_reminder;
  }

  isClinicDateisPast(): boolean {
    let is_clinic_date_past: boolean = false;
    let today = new Date();
    for (let index = 0; index < this.pcaDetailsForm.value.clinicList.length; index++) {
      const loc = this.pcaDetailsForm.value.clinicList[index];
      let clinicDate: Date = null;
      let initialClinicDate: Date = null;
      if (typeof (loc.clinicDate) == "string") {
        var dt = loc.clinicDate.split("/");

        clinicDate = new Date(dt[2], dt[0] - 1, dt[1]);
      } else {
        clinicDate = loc.clinicDate;
      }
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      clinicDate = new Date(clinicDate.getFullYear(), clinicDate.getMonth(), clinicDate.getDate(), 0, 0, 0);
      if (clinicDate < today) {
        is_clinic_date_past = true;
      }
    }
    return is_clinic_date_past;
  }
  //Waiting for user input to Confirm
  confirm(hdr: string, msg: string, action_status) {
    this.confirmationService.confirm({
      message: msg,
      header: hdr,
      accept: () => {
        let is_data_time_changed = this.verifyDateAndTimeUpdated(action_status);
        if (is_data_time_changed && ((action_status == "Confirmed" || action_status == "Submit") && (!this.pcaDetailsSource.clinicList[0].isCancelled && !this.pcaDetailsSource.clinicList[0].isCompleted))) {
          this.setDateTimeChangeDialogInfo(action_status);
          return false;
        } else {
          this.continueWithSaving(action_status);
          return true;
        }
      },
      reject: () => {
        return false;
      }
    });
  }

  //Sets the Message Dialog header, body info and opens.
  setDateTimeChangeDialogInfo(action_status: string) {
    this.changeDateTimeBody = ErrorMessages["eventDateTimeChanged"];
    this.changeDateTimeHeader = ErrorMessages['impRmndrFor2Wks'];
    this.changeDateTimeActionStatus = action_status;
    this.changedDateTime = true;
  }

  //Shows the message dialog.
  showDialog(msg_header: string, msg_body: string, is_error: boolean, is_remove_cancelled_status:boolean) {
    this.dialogHeader = msg_header;
    this.dialogBody = msg_body;
    this.isErrorMessage = is_error;
    this.showRemoveCancelledStatusResult = is_remove_cancelled_status;
    this.showSimpleDialog = true;
  }

  //closes the message dialog.
  closeSimpleDialog() {
    if (this.noPCAData) {
      this.noPCAData = false;
      window.location.href = '/communityoutreach/pcahome';
      return false;
    }
    else if (this.isErrorMessage) {
      this.isErrorMessage = false;
    } else if(this.showRemoveCancelledStatusResult){
      this.loadClinicDataAfterRemove();
    }else {
      this.getPCADetails();
    }
    this.showSimpleDialog = false;
  }

  //Validates the entered email
  validateEmail(email: any, show_msg_dialog: boolean): boolean {
    let is_valid: boolean = true;
    //if ((this.pcaDetailsForm.value.email != null || this.pcaDetailsForm.value.email != undefined) && this.pcaDetailsForm.value.email.trim().length > 0) {
    if ((email != null || email != undefined) && (email.trim().length > 0 && !this.utility.validateSingleEmail(email.trim()))) {
      is_valid = false;
    } else {
      is_valid = true;
    }
    return is_valid;
  }

  //Validates the location address.
  validateLocationAddress() {
    let controlclinicList = <FormArray>this.pcaDetailsForm.controls['clinicList'];
    let index_clinic;
    for (index_clinic = 0; index_clinic < controlclinicList.length; index_clinic++) {

      let address1: string = controlclinicList.controls[index_clinic].get('naClinicAddress1').value;
      let address2: string = controlclinicList.controls[index_clinic].get('naClinicAddress2').value;

      let address = (address1 + address2).trim().replace(/[\[\]().-]+/g, '');
      address = address.replace(/\s/g, '');
      if (address.toLowerCase().includes("pobox")) {
        this.dialogHeader = "Alert";
        this.dialogBody = "Addresses containing a P.O. Box are not allowed. Please enter a valid location address.";

        this.showDialog(this.dialogHeader, this.dialogBody, false,false);
        return;
      }
    }
  }

  //Applies the Validators on quantities based on the action performing.
  setValidatorsAndValidateQuantities(totalImmAdministeredIsOK: Boolean, prelimPositiveIsOk: Boolean, action_type: string) {

    let control: FormGroup = <FormGroup>this.pcaDetailsForm.controls['clinicImmunizationList']['controls'][0];

    if (control != null || control != undefined) {
      if (((action_type == "Submit" && this.pcaDetailsSource.clinicList[0].isCompleted)) || (action_type == "Completed")) {
        control.get('totalImmAdministered').setValidators([Validators.required]);
        control.get('totalImmAdministered').updateValueAndValidity();
        if (this.eventDetails.clinicTypeCode != 12) {
          control.get('preliminaryPositives').setValidators([Validators.required]);
          control.get('preliminaryPositives').updateValueAndValidity();
        }
      }
      else {
        control.get('totalImmAdministered').setValidators(null);
        control.get('totalImmAdministered').updateValueAndValidity();
        if (this.eventDetails.clinicTypeCode != 12) {
          control.get('preliminaryPositives').setValidators(null);
          control.get('preliminaryPositives').updateValueAndValidity();
        }
      }

      control.get('estimatedQuantity').setValidators([Validators.required, Validators.pattern("^((?!(0))[0-9]+)$")]);
      control.get('estimatedQuantity').updateValueAndValidity();

      //Need to look into the validations
      this.utility.validateAllFormFields(<FormGroup>control);
      if (action_type == 'Completed'/* || action_type == "Confirmed"*/) {
        if (totalImmAdministeredIsOK && control.get('totalImmAdministered').value != null) {
          if (Number(control.get('totalImmAdministered').value) < 0 && control.get('totalImmAdministered').value != "") {
            totalImmAdministeredIsOK = false;
          }
        }
        if (this.eventDetails.clinicTypeCode != 12 && prelimPositiveIsOk && control.get('preliminaryPositives').value != null) {
          if (Number(control.get('preliminaryPositives').value) < 0 && control.get('preliminaryPositives').value != "") {
            prelimPositiveIsOk = false;
          }
        }
      }
    }
  }

  //Validates the Confirmed By field data.
  setValidatorAndValidateConfirmedClientName(action_type: string) {

    let control = <FormArray>this.pcaDetailsForm.controls['clinicList'];
    let index;
    for (index = 0; index < control.length; index++) {
      if (((action_type == "Submit" && this.pcaDetailsSource.clinicList[0].isCompleted) && index == 0) || (action_type == "Completed" || action_type == "Confirmed")) {
        control.controls[0].get('confirmedClientName').setValidators([Validators.required]);
        control.controls[0].get('confirmedClientName').updateValueAndValidity();
      }
      else {
        control.controls[0].get('confirmedClientName').setValidators(null);
        control.controls[0].get('confirmedClientName').updateValueAndValidity();
      }
      this.utility.validateAllFormFields(<FormGroup>control.controls[index]);
    }
  }

  //Validates the feedback/notes
  validateFeedbackOrNotes(action_status: string) {
    if (action_status == "Cancelled") {
      this.pcaDetailsForm.controls["feedbackNotes"].setValidators(Validators.required);
      this.pcaDetailsForm.controls["feedbackNotes"].updateValueAndValidity();
    }
    else {
      this.pcaDetailsForm.controls["feedbackNotes"].setValidators(null);
      this.pcaDetailsForm.controls["feedbackNotes"].updateValueAndValidity();
    }
  }

  //Validates the data and proceeds to save or seeks the user input to save.
  validateAndSaveOrContinue(action_status: string) {
    let totalImmAdministeredIsOK: Boolean = new Boolean(true);
    let preliminaryPositivesIsOk: Boolean = new Boolean(true);

    //Sets the validators and validate quantity based on action status.
    this.setValidatorsAndValidateQuantities(totalImmAdministeredIsOK, preliminaryPositivesIsOk, action_status);

    if (action_status == 'Confirmed' || action_status == 'Completed' || action_status == 'Cancelled' || action_status == 'Submit') {
      //Sets validators and validates confirmed client name 
      this.setValidatorAndValidateConfirmedClientName(action_status);
    }

    this.validateFeedbackOrNotes(action_status);
    if (this.pcaDetailsForm.valid) {
      if(this.pcaDetailsForm.value.phone.indexOf("X") > -1){
        this.pcaDetailsForm.controls.phone.setValue(null);
        this.pcaDetailsForm.controls.phone.updateValueAndValidity();
        let sumMsg=ErrorMessages['serverError'];
        let  erMsg = ErrorMessages['MandatoryFields'];
        this.showDialog(sumMsg, erMsg, true,false);
        return false;
      }

      if(this.pcaDetailsForm.value.clinicList[0].naClinicContactPhone.indexOf("X") > -1){
      this.pcaDetailsForm.value.phone
      let icontrol = <FormArray>this.pcaDetailsForm.controls['clinicList'];
            icontrol.controls[0].get('naClinicContactPhone').setValue(null);
            icontrol.controls[0].get('naClinicContactPhone').updateValueAndValidity();
            this.utility.validateAllFormFields(<FormGroup>icontrol.controls[0]);
            let sumMsg=ErrorMessages['serverError'];
            let  erMsg = ErrorMessages['MandatoryFields'];
            this.showDialog(sumMsg, erMsg, true,false);
            return false;
          }



      if (action_status != "Submit") {
        this.validateEmail(this.pcaDetailsForm.value.email, true);
      }
      this.validateLocationAddress();
      let ret: Boolean = false;
      // ret = this.isClinicDateReminderBefore2WksReq(action_status);
      if (!ret) {
        let is_data_time_changed = this.verifyDateAndTimeUpdated(action_status);
        if (is_data_time_changed && ((action_status === "Confirmed" || action_status === "Submit") && (!this.pcaDetailsSource.clinicList[0].isCancelled && !this.pcaDetailsSource.clinicList[0].isCompleted))) {
          this.setDateTimeChangeDialogInfo(action_status);
          return false;
        }
      }
      if (!ret || (action_status === "Confirmed" && this.isClinicDateisPast()) || ((action_status == "Completed" || action_status == "Cancelled") && this.pcaDetailsForm.value.clinicList.length <= 1)) {
        switch (action_status) {
          case "Confirmed":
          case "Completed":
          case "Cancelled":
            this.continueWithSaving(action_status);
            break;
          default:
            let pca_details = this.saveData(null);
            pca_details.clinicContactStatus = "Submit";
            /*this._alertService.clear();
            this._alertService.info(ErrorMessages['eventDateReminderAfter2WeeksEN'], false);*/
            this.scrolling();
            this.updatePCADetails(pca_details, ErrorMessages['eventSave']);
            break;
        }
      } else {
        //Bypassing 2 weeks before validation for the clinics past the clinic date in case they just didn’t Confirm before the event.
        if (!this.isClinicDateisPast()) {
          let sumMsg = ErrorMessages['impRmndrFor2Wks'];
          let errMsg = ErrorMessages['eventDateReminderBefore2WeeksEN'];
          var userProfile = SessionDetails.GetUserProfile();
          if (userProfile.userRole.toLowerCase() === "admin" ||
            /*userProfile.userRole.toLowerCase() == "regional vice president" ||*/
            userProfile.userRole.toLowerCase() == "regional healthcare director" || 1==1) {
            this.confirm(sumMsg, errMsg, action_status);
            return
          }
          else {
            errMsg = ErrorMessages['eventDateReminderBefore2WeeksEN'];
            this.showDialog(sumMsg, errMsg, true,false);
            return
          }
        }
      }
    }
    else {
      if (!this.validateEmail(this.pcaDetailsForm.value.email, false)) {
        this.utility.validateAllFormFields(this.pcaDetailsForm);
        this.showDialog(ErrorMessages['serverError'], ErrorMessages['invalidEmail'], true,false);
        return;
      }
      this.showDialog(ErrorMessages['serverError'], ErrorMessages['MandatoryFields'], true,false);
      this.utility.validateAllFormFields(this.pcaDetailsForm);
      return;
    }
  }

  //Verify if user has changed the date and time
  verifyDateAndTimeUpdated(action_status: string): boolean {
    let is_clinic_date_time_changed: boolean = false;

    try {
      const loc = this.pcaDetailsForm.value.clinicList[0];
      let clinic_date: Date = null;
      let initial_clinic_st_time = null;
      let initial_clinic_end_time = null;
      let initial_clinic_date: Date = null;
      let st_time = null;
      let end_time = null;
      if (typeof (loc.clinicDate) == "string") {
        if (loc.clinicDate.indexOf("/") > -1) {
          let dt = loc.clinicDate.split("/");
          clinic_date = new Date(dt[2], dt[0] - 1, dt[1]);
        } else {
          let dt = new Date(loc.clinicDate);
          let dt_temp = dt.getMonth() + "/" + dt.getDate() + "/" + dt.getFullYear();
          let dt_temp_comp = dt_temp.split("/");
          clinic_date = new Date(parseInt(dt_temp_comp[2]), parseInt(dt_temp_comp[0]), parseInt(dt_temp_comp[1]));
        }

      } else {
        clinic_date = loc.clinicDate;
      }
      if (typeof (this.pcaDetailsSource.clinicList[0].clinicDate) == "string") {
        if (this.pcaDetailsSource.clinicList[0].clinicDate.indexOf("/") > -1) {
          let idt = this.pcaDetailsSource.clinicList[0].clinicDate.split("/");
          initial_clinic_date = new Date(parseInt(idt[2]), parseInt(idt[0]) - 1, parseInt(idt[1]));
        } else {
          let idt = new Date(this.pcaDetailsSource.clinicList[0].clinicDate);
          let idt_temp = idt.getMonth() + "/" + idt.getDate() + "/" + idt.getFullYear();
          let idt_temp_components = idt_temp.split("/");
          //initial_clinic_date = new Date(parseInt(idt_temp_components[2]), parseInt(idt_temp_components[0]), parseInt(idt_temp_components[1]));
          initial_clinic_date = new Date(parseInt(idt_temp_components[2]), parseInt(idt_temp_components[0]), parseInt(idt_temp_components[1]));
        }
        //var idt = this.pcaDetailsSource.clinicList[0].clinicDate.split("/");
        //initial_clinic_date = new Date(parseInt(idt[2]), parseInt(idt[0]) - 1, parseInt(idt[1]));
      } else {
        initial_clinic_date = this.pcaDetailsSource.clinicList[0].clinicDate;
      }

      initial_clinic_st_time = this.pcaDetailsSource.clinicList[0].naClinicStartTime;
      initial_clinic_end_time = this.pcaDetailsSource.clinicList[0].naClinicEndTime;

      st_time = this.pcaDetailsForm.value.clinicList[0].naClinicStartTime;
      end_time = this.pcaDetailsForm.value.clinicList[0].naClinicEndTime;

      if (initial_clinic_date != null &&
        (initial_clinic_date.getFullYear() != clinic_date.getFullYear() || initial_clinic_date.getMonth() != clinic_date.getMonth() ||
          initial_clinic_date.getDate() != clinic_date.getDate())) {
        is_clinic_date_time_changed = true;
      }
      else if (initial_clinic_st_time != null && (initial_clinic_st_time != st_time || initial_clinic_end_time != end_time)) {
        is_clinic_date_time_changed = true;
      }
    } catch (e) {
      return e;
    }
    return is_clinic_date_time_changed;
  }

  //Displays the confirmation message to user when delete button is clicked.
  deleteAgreementData() {
    this.deleteAgreement = true;
  }

  //Initiates the API call to deletes the agreement.
  deleteClinicDetails() {

    this.deleteAgreement = false;
    let clinic_details = new ClinicDetailsDelete();
    clinic_details.userPk = this.userProfile.userPk;
    clinic_details.username = this.userProfile.userName;
    clinic_details.businessName = this.pcaDetails.opportunityInfo.businessName;
    clinic_details.clinicPk = this.pcaDetails.clinicList[0].clinicPk;
    clinic_details.clinicStoreId = this.pcaDetails.clinicList[0].clinicStoreId;
    clinic_details.contractedStoreId = this.pcaDetails.opportunityInfo.contractedStoreId;
    clinic_details.clinicType = this.pcaDetails.opportunityInfo.clinicType;;
    clinic_details.clinicName = this.pcaDetails.clinicList[0].naClinicLocation.replace("LOCATION ", "CLINIC ");

    this.pcaDetailsService.deletePCADetails(
      clinic_details).subscribe((res: any) => {
        //if (res.status == "200") {
          this.showDialog(ErrorMessages['eventDetails'], ErrorMessages['eventDetailsDelete'], false,false);
          this.router.navigateByUrl("/communityoutreach/storehome");
        //}
      });
    window.scrollTo(0, 0);
  }

  //Cancels the delete agreement window.
  deleteAgreementCancel() {
    this.deleteAgreement = false;
  }

  //Downloads the Proof o Docs.
  downloadProofOfDocs(value: any) {
    let pod_path = environment.RESOURCES.PROOF_OF_DOCUMENTS +'/'+ value;
    window.open(pod_path);
  }

  //Navigates to home page when Home button is clicked.
  goToHome() {
    this.router.navigateByUrl("/communityoutreach/storehome");
  }

  //Opens the contract agreement in view mode (Read Only).
  viewContractAgreement() {
    sessionStorage["clinic_agreement_pk"] = this.pcaDetails.opportunityInfo.clinicAgreementPk;
    sessionStorage['clinic_type'] = this.eventDetails.clinicTypeCode;
    sessionStorage["fromPcaDetails"] = true;
    if(new Date(this.clinicAgreementDateApproved) >= new Date(this.PcaReleaseDate2022_1) || this.clinicAgreementDateApproved == null)
    {
      this.router.navigateByUrl('/rhdContractAgreement-2023-1');
    }
    else
    {
      this.router.navigateByUrl('/communityoutreach/rhdContractAgreement');
    }
  }

  //Continue to save the changes. 
  continueToChange() {
    this.changedDateTime = false;
    this.continueWithSaving(this.changeDateTimeActionStatus);
    this.changeDateTimeActionStatus = "";
    this.changeDateTimeBody = "";
    this.changeDateTimeHeader = "";

  }

  //Cancel the changes.
  cancelTheChange() {
    this.changeDateTimeActionStatus = "";
    this.changeDateTimeBody = "";
    this.changeDateTimeHeader = "";
    this.changedDateTime = false;
  }

  //Returns the difference between two objects.
  getModelDiff() {
    let temp: any = JSON.stringify(this.pcaDetailsSource)
    temp = JSON.parse(temp);

    this.updateDataFromFormFields(temp);
    temp.clinicList.forEach(item => {
      try {
        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });
      } catch (e) {
        console.log('save changes' + e);
      }
    });
    let obj1 = this.deepCopy(this.pcaDetailsSource);
    obj1.clinicList.forEach(item => {
      try {
        item.clinicImzQtyList.forEach(imzQtyItem => {
          delete imzQtyItem["imzNameAndPmtMethod"];
        });
      } catch (e) {
        console.log('save changes' + e);
      }
    });
    let diff = this.utility.getDifference(obj1, temp);
    return diff;
  }

  //Copied the from one object to another object 
  deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  //Leaves the current route (page).
  leaveRoute() {
    this.showCanRouteChange = false;
    this.messageService.setCanShowRouteChangeConfirmation(false);
    this.messageService.setCanRouteChange(true);
  }

  //remains or continue with the current route (page).
  cancelRoute() {
    this.showCanRouteChange = false;
    this.messageService.setCanShowRouteChangeConfirmation(false);
    this.messageService.setCanRouteChange(false);
  }

  //
  restrictSpaces(event: any) {
    //if(event.keyCode == 32){
    //  return false;
    //}else{
    return true;
    //}
  }

  getPrevSiblingInHist(obj: any, index: number, inner_index: number, value: any) {
    try {
      if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].indexOf('<br') > -1)
        return 'minHeight65';
      else if (value.length > 500) {
        return 'enableScroller';
      }
      else if (value.length > 400) {
        return 'minHeight150';
      }
      else if (value.length > 300) {
        return 'minHeight100';
      }
      else if (value.length > 41) {
        return 'minHeight90';
      }
      else
        return '';
    }
    catch (e) {
      return '';
    }
  }

  getNextSiblingInHist(obj: any, index: number, inner_index: number) {
    try {
      if (obj.updateField[index] && obj.updateField[index].value[inner_index] && obj.updateField[index].value[inner_index].indexOf('<br') > -1)
        return 'minHeight65';
      else if ((obj.updatedValue[index] && obj.updatedValue[index].value[inner_index] && obj.updatedValue[index].value[inner_index].length > 500)) {
        return 'enableScrollerHeight';
      }
      else if ((obj.updatedValue[index] && obj.updatedValue[index].value[inner_index] && obj.updatedValue[index].value[inner_index].length > 400)) {
        return 'minHeight150';
      }
      else if ((obj.updatedValue[index] && obj.updatedValue[index].value[inner_index] && obj.updatedValue[index].value[inner_index].length > 300)) {
        return 'minHeight100';
      }
      else if ((obj.updatedValue[index] && obj.updatedValue[index].value[inner_index] && obj.updatedValue[index].value[inner_index].length > 41)) {
        return 'minHeight90';
      }
      else
        return '';
    }
    catch (e) {
      return '';
    }
  }

  clinicNotConfirmedMsgOk() {
    this.clinicNotConfirmedMsgDlg = false;
  }

  showClinicNotConfirmedDialog(msgSummary: string, msgDetail: string) {
    this.dialogBody = msgDetail;
    this.dialogHeader = msgSummary;
    this.clinicNotConfirmedMsgDlg = true;
  }

  showCancelEventConfirmation() {
    this.dialogBody =  ErrorMessages.confirmCancelEvent;
    this.dialogHeader = "Review Event Cancellation";   
    this.cancelEvent = true;
  }

  removeCancelledStatus(){
    this.showRemoveCancelledStatusDlg = true;
  }

  cancelRemoveCancelledStatus(){
    this.showRemoveCancelledStatusDlg = false;
  }

  continueToRemoveCancelledStatus(){
    var clinic_pk = this.pcaDetails.clinicList[0].clinicPk;
    this.pcaDetailsService.removeClinicCancelledStatus(
      clinic_pk).subscribe((res: any) => {
        if (res == "success") {
          
          this.showRemoveCancelledStatusDlg = false;
          this.showRemoveCancelledStatus= false;
          this.showRemoveCancelledStatusDialogResult(ErrorMessages['clinicDetails'],  'The last logged status has been removed successfully.');

          //this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['removeCancelledClinicStatus'],false,true);
        }
      },error =>{
        this.showRemoveCancelledStatusDlg = false;
        this.showDialog(ErrorMessages['clinicDetails'], ErrorMessages['error'],true,false);
      });
  }
  
  showRemoveCancelledStatusDialogResult(msgSummary: string, msgDetail: string) {
    this.dialogMsg = msgDetail;
    this.dialogSummary = msgSummary;
    this.showRemoveCancelledStatusResult = true;
  }

  loadClinicDataAfterRemove(){
    this.showRemoveCancelledStatusResult = false;
    this.validateCancel = null;
    this.validateComplete = null;
    this.validateConfirm = null;
    this.showRemoveCancelledStatus = false;
    this.getPCADetails();
    this.pcaDetailsForm.updateValueAndValidity();       
  }

}

