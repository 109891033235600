import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Util } from '../../../utility/util';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AddTestingOrganizationService {

  constructor(private _http:HttpClient) { }

  getTestingPartnerNames(state_name)
  {
    var url = environment.API_URL + environment.RESOURCES.TESTING_PARTNER_NAMES + "?state=" + state_name;
    return this._http.get(url,Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

}


