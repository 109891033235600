import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduledClinics } from '../../../models/ScheduledClinics';
import { Util } from '../../../utility/util';
import { environment } from '../../../../environments/environment';
import { SessionDetails } from '../../../utility/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ScheduledClinicsReportService {
  private _baseURL: string = environment.API_URL;
  private _url: string = this._baseURL;
  constructor(private http: HttpClient) { }
  public getScheduledClinicDetails(scheduled_clinics: ScheduledClinics): Observable<any> {
    let url: any = this._url + "/api/JsScheduledClinicsDetails_get";
    return this.http.post(url, scheduled_clinics, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportReportData(sheduled_clinics: ScheduledClinics) {
    let user_data = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ, user_data.userName + ':' + user_data.password);
    let url = sheduled_clinics.isPreviousSeason ? (environment.API_URL_V2 + environment.RESOURCES.SCHEDULED_CLINIC_REPORT_V2) : (environment.API_URL + environment.RESOURCES.SCHEDULED_CLINIC_REPORT);
    let req_headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + usernamePassword);
    return this.http.post(url, sheduled_clinics, { headers: req_headers, observe: 'response' as 'body', responseType: 'blob' as 'json' }).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }

  public getServiceFeeDetails(scheduled_clinics: ScheduledClinics): Observable<any> {
    let url: any = this._url + "/api/JsClinicfeesDetails_get";
    return this.http.post(url, scheduled_clinics, Util.getRequestHeadersV1()).pipe(catchError(Util.handleErrorV2));
  }

  public exportServiceFeeReportData(sheduled_clinics: ScheduledClinics) {
    let user_data = SessionDetails.GetUserInfo();
    let usernamePassword = SessionDetails.getE(SessionDetails.absJ, user_data.userName + ':' + user_data.password);
    let url = this._url + "/api/JsClinicfeesDetails_downloadReport";
    let req_headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + usernamePassword);
    return this.http.post(url, sheduled_clinics, { headers: req_headers, observe: 'response' as 'body', responseType: 'blob' as 'json' }).pipe(catchError(Util.handleErrorV2));
    // .map(res => {
    //   var contentDisposition = res.headers.get('Content-Disposition');
    //   var result = contentDisposition.split(';')[1].trim().split('=')[1];
    //   this.filename = result;
    //   return res.blob()
    // });
  }
}